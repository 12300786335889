import 'bootstrap/dist/css/bootstrap.min.css';
import "./src/styles/global.scss";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import React from 'react'
import {ParallaxProvider} from "react-scroll-parallax";

export const onServiceWorkerUpdateReady = () => window.location.reload();
export const shouldUpdateScroll = () => false

export const wrapRootElement = ({element}) => {
    return <I18nextProvider i18n={i18n}>
        <ParallaxProvider>
            {element}
        </ParallaxProvider>
    </I18nextProvider>
}
