exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/aboutUs.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cooperation-tsx": () => import("./../../../src/pages/cooperation.tsx" /* webpackChunkName: "component---src-pages-cooperation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-templates-all-products-template-tsx": () => import("./../../../src/templates/allProductsTemplate.tsx" /* webpackChunkName: "component---src-templates-all-products-template-tsx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-en-do-you-need-roof-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/opt/buildhome/repo/web/src/articles/en/do-you-need-roof.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-en-do-you-need-roof-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-en-energy-independence-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/opt/buildhome/repo/web/src/articles/en/energy-independence.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-en-energy-independence-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-en-how-many-do-you-need-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/opt/buildhome/repo/web/src/articles/en/how-many-do-you-need.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-en-how-many-do-you-need-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-en-photovoltaics-for-schools-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/opt/buildhome/repo/web/src/articles/en/photovoltaics-for-schools.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-en-photovoltaics-for-schools-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-en-strong-sides-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/opt/buildhome/repo/web/src/articles/en/strong-sides.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-en-strong-sides-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-en-what-factors-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/opt/buildhome/repo/web/src/articles/en/what-factors.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-en-what-factors-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-pl-do-you-need-roof-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/opt/buildhome/repo/web/src/articles/pl/do-you-need-roof.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-pl-do-you-need-roof-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-pl-energy-independence-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/opt/buildhome/repo/web/src/articles/pl/energy-independence.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-pl-energy-independence-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-pl-how-many-do-you-need-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/opt/buildhome/repo/web/src/articles/pl/how-many-do-you-need.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-pl-how-many-do-you-need-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-pl-photovoltaics-for-schools-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/opt/buildhome/repo/web/src/articles/pl/photovoltaics-for-schools.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-pl-photovoltaics-for-schools-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-pl-strong-sides-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/opt/buildhome/repo/web/src/articles/pl/strong-sides.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-pl-strong-sides-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-pl-what-factors-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/opt/buildhome/repo/web/src/articles/pl/what-factors.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-pl-what-factors-mdx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/productTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */)
}

